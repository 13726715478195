body {
  margin: 0;
  padding: 0;
  font-family: 'proxima_novalight';
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

@font-face {
  font-family: 'proxima_novalight';
  src: url('./containers/assets/css/fonts/proximanova-light_2-webfont.woff2') format('woff2'),
       url('./containers/assets/css/fonts/proximanova-light_2-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('./containers/assets/css/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
       url('./containers/assets/css/fonts/proximanova-semibold-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}